















































import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  NavBar,
  Field,
  Icon,
  Dialog,
  Popup,
  Picker,
} from "vant";
import { SlideCheck } from "@/types";
@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
})
export default class RealMobileBond extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public component!: boolean; // 是否为余额入口
  public isFirstSend = true;
  public loginDisabled = true;
  public reciveCode = "获取验证码";
  public hideshow = true; //显示或隐藏footer
  public docmHeight = document.documentElement.clientHeight; //默认屏幕高度
  public showHeight = document.documentElement.clientHeight; //实时屏幕高度
  public showPrivacy = false;
  public form = {
    phone: "",
    code: "",
    phoneCode: "",
    phoneArea: "86",
    token: "",
  };
  public showPicker = false;
  public countdown = {
    num: 60,
    status: false,
  };
  public redirect = "/mine";
  @Watch("form.phoneCode")
  cloginDisabled(): void {
    this.$emit("pullCode", this.form.phoneCode);
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }
  phoneCodeChange(): void {
    this.$emit("code", this.form.phoneCode);
  }
  mounted(): void {
    // window.onresize监听页面高度的变化,防止底部图标上来
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
        if (this.docmHeight > this.showHeight) {
          this.hideshow = false;
        } else {
          this.hideshow = true;
        }
      })();
    };
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
  closeLogin(): void {
    this.$router.go(-1);
  }
  // 修改手机区号
  onConfirm(value: { key: string }): void {
    this.form.phoneArea = value.key;
    this.changePickerStatus();
  }
  // 手机区号选择器打开&关闭
  changePickerStatus(): void {
    this.showPicker = !this.showPicker;
  }

  checkPhoneCode(): boolean {
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6) {
        return true;
      } else {
        this.$toast("请输入正确的验证码");
        return false;
      }
    } else {
      this.$toast("请输入正确的验证码");
      return false;
    }
  }

  // 发送短信验证码
  sendCodeMessage(): void {
    let phoneCaptchaRequest = {
      account: this.UserModule.phone,
      sessionId: "",
      sig: "",
      token: "",
    };
    if (this.component) {
      this.$api.memberApi.eWallet.sendCaptcha(phoneCaptchaRequest, (data) => {
        return data;
      });
    } else {
      this.$api.memberApi.setting.getMemberCaptcha(
        phoneCaptchaRequest,
        (data) => {
          return data;
        }
      );
    }
    if (this.isFirstSend == false) {
      this.reciveCode = "再次获取";
    }
    this.$toast("验证码已发送，请注意查收！");
    this.countDown();
  }

  // 验证码发送倒计时
  countDown(): void {
    if (this.countdown.num === 0) {
      this.countdown.status = false;
      this.countdown.num = 60;
      return;
    }
    this.countdown.status = true;
    this.countdown.num--;

    setTimeout(() => {
      this.countDown();
    }, 1000);
  }
}
